import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { LEGAL_PRIVACY_URL, LEGAL_TERMS_URL } from "../../constants/pageUrls"

const LegalIndexPage = () => (
  <Layout>
    <SEO title="PayPerks Legal Pages | Index" />
    <div className="legal-page corp-legal-index">
      <div className="content-wrapper max-640">
        <h1 className="mb-0">PayPerks Legal Pages</h1>
        <hr className="hairline green1 mt-3 mb-3" />
        <div className="section mb-5 content-wrap">
          <p className="mb-0">
            These are the legal documents for the PayPerks corporate site.
          </p>
          <hr className="hairline green1 mt-3 mb-3" />
          <ul className="list-bullet mb-3">
            <li>
              <Link to={LEGAL_TERMS_URL}>
                PayPerks General Terms &amp; Conditions
              </Link>
            </li>
            <li>
              <Link to={LEGAL_PRIVACY_URL}>PayPerks Privacy Policy</Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </Layout>
)

export default LegalIndexPage
